$(function () {
    setHeaderScroll();
    hamburger();

});


//Lightcase
$(document).ready(function() {
	$('a[data-rel^=lightcase]').lightcase();

  	//Sub-menu
	var button_text = "Zobrazit navigaci";
	$('#page-detail__menu-mobile-trigger').on('click', function (e) {
		e.preventDefault();
		$(this).text(
			button_text == "Skrýt navigaci" ? "Zobrazit navigaci" : "Skrýt navigaci"
		);
		$('#page-detail__submenu').slideToggle(200);
		button_text = $(this).text();
	});
});
